
# Note that in dev this won't automatically recompile 



window.Fun ||= {}

window.Fun.app = "rotaville"

window.translations = {}

window.locale_loaded = "en_ROTAVILLE"
  
window.t = (key) ->
  if translations[key]
    return translations[key]
  else
    error_string = "MISSING_TRANSLATION(#{key})"
    console?.log error_string
    return error_string

