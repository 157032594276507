/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'external' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require('../four/sentry_init.js')

require('../four/external_globals.js')
require('../four/app_scope.js.coffee')

require('../four/vendor/backbone-rotaville-1.4.js')
// require('../four/backbone_sync.js')
require('../four/translations.js.coffee.erb')
require('../four/jtip.js')
require('../four/jlSlider.js')
require('../four/vendor/rails.js')
require('../four/general.js')
require('../four/start_toastie.js')
require('../four/start_bs_modal.js')
require('../four/jsx/video_modal.js')
require('../four/jsx/pic_modal.js')
// require('../four/channels/guest_channel.js')
// require('../four/models/external_index.js')
require('../four/models/external_slim_index.js')
// require('../four/start_chat_widget.js')
require('../four/jready.js.coffee')

